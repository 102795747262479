<div class="sidebar-header px-5 py-3 sticky-top text-center">
  <h3 class="text-white">NB Medical Admin</h3>
</div>
<ul class="list-unstyled components">
  <li
    class="d-flex align-self-center justify-content-start flex-fill border-bottom"
  >
    <a
      class="align-items-baseline align-self-center d-flex flex-fill justify-content-start p-3"
      routerLink="/"
      (click)="clicked()"
    >
      <i class="fas fa-home me-2"></i>
      <span> Home</span>
    </a>
  </li>
  <ng-container *ngFor="let link of links$ | async">
    <nba-nav-item-divider
      *ngIf="$any(link).dividerName; else showLink"
      [divider]="$any(link)"
    >
    </nba-nav-item-divider>
    <ng-template #showLink>
      <nba-nav-item-link *ngIf="$any(link).onClick" [link]="$any(link)">
      </nba-nav-item-link>
      <nba-nav-item-routed
        *ngIf="!$any(link).onClick"
        [link]="$any(link)"
      ></nba-nav-item-routed>
    </ng-template>
  </ng-container>
  <!-- <li
    class="d-flex align-self-center justify-content-start flex-fill border-bottom"
    [ngClass]="{ 'd-lg-none': link.mobileOnly }"
  >
    <a
      *ngIf="!link.onClick"
      class="align-items-baseline align-self-center d-flex flex-fill justify-content-start p-3"
      [routerLink]="link.link"
      routerLinkActive="active"
      (click)="clicked()"
    >
      <i class="{{ link.icon }} me-2"> </i>
      <span> {{ link.name }}</span>
    </a>
    <a
      *ngIf="link.onClick"
      class="d-flex align-self-center justify-content-center flex-fill p-3 cursor-pointer"
      (click)="link.onClick(); clicked()"
    >
      {{ link.name }}
    </a>
  </li> -->
</ul>
