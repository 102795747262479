import { Component } from '@angular/core';
import { AdminModalTemplate } from '../admin-modal/admin-modal-template';

@Component({
  selector: 'nba-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.scss',
})
export class ConfirmModalComponent extends AdminModalTemplate<
  {
    heading: string;
    confirmText: string;
    rejectText: string;
    messageHtml: string | null;
    messageText: string | null;
  },
  boolean
> {
  constructor() {
    super();
  }

  confirm() {
    this.performAction.emit(true);
  }

  reject() {
    this.performAction.emit(false);
  }
}
