import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { map } from 'rxjs/operators';

import { PagedCollection } from '../item-pager/paged-collection';
import { AdminOperationResult } from '../shared/dtos/admin-operation';
import { RequestService } from '../shared/request.service';
import { AddJobListing } from './dtos/add-job-listing';
import { AdminSearchJobListings } from './dtos/admin-search-job-listings';
import { JobListingInfo } from './dtos/job-listing-info';
import { UpdateJobListing } from './dtos/update-job-listing';

@Injectable({
  providedIn: 'root',
})
export class JobListingsService {
  constructor(private request: RequestService) {}

  search(query: AdminSearchJobListings) {
    return this.request
      .adminQuery<PagedCollection<JobListingInfo>>(
        'AdminSearchJobListings',
        query
      )
      .pipe(map((data) => PagedCollection.parseData(data)));
  }
  getTotalToApprove() {
    return this.request.adminQuery<number>('AdminJobListingsToApprove', {});
  }

  add(listing: AddJobListing) {
    return this.request.adminCommand<AdminOperationResult>(
      'AddJobListing',
      listing
    );
  }

  getEditObject(id: string) {
    return this.request.adminQuery<UpdateJobListing>('GetUpdateJobListing', {
      id,
    });
  }
  update(listing: UpdateJobListing) {
    return this.request.adminCommand<AdminOperationResult>(
      'UpdateJobListing',
      listing
    );
  }

  approve(info: JobListingInfo) {
    return this.request.adminCommand<AdminOperationResult>(
      'ApproveJobListing',
      { id: info.id }
    );
  }

  reject(info: JobListingInfo) {
    return this.request.adminCommand<AdminOperationResult>('RejectJobListing', {
      id: info.id,
    });
  }

  delete(info: JobListingInfo) {
    return this.request.adminCommand<AdminOperationResult>('DeleteJobListing', {
      id: info.id,
    });
  }

  getFilterObject(query: AdminSearchJobListings): Params {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }

  private getFilterData(query: AdminSearchJobListings) {
    const queryData: any = { ...query };
    const activeKeys = Object.keys(queryData).filter(
      (key) =>
        queryData[key] !== undefined &&
        queryData[key] !== null &&
        queryData[key] !== ''
    );

    const data = {};
    activeKeys.forEach((key) => (data[key] = queryData[key]));

    return { activeKeys, data };
  }
}
