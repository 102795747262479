<div class="card">
  <div class="card-body">
    <span class="h5 card-title">Licences Not Synced</span>
    <button class="btn btn-info float-end mb-2" (click)="getData()">
      Refresh
    </button>
    <ng-container *ngIf="!loading">
      <div *ngIf="licences && shownLicences.data.length > 0; else noLicences">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Created On</th>
              <th>Assigned To</th>
              <th>Sync Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let licence of shownLicences.data">
              <td class="align-middle">
                {{ licence.licenceId }}
              </td>
              <td class="align-middle">
                {{ licence.createdOn | date : "short" }}
              </td>
              <td class="align-middle">
                {{ licence.currentAssignment?.user.email }}
              </td>
              <td class="align-middle">
                {{ licence.syncStatus | breakUpString }}
              </td>
              <td class="text-end align-middle">
                <div class="btn-group">
                  <a
                    *ngIf="
                      licence.syncStatus === 'Error' ||
                      licence.syncStatus === 'Syncing'
                    "
                    class="btn btn-warning me-1"
                    (click)="setNotSynced(licence.licenceId)"
                    ><i class="fas fa-sync-alt"></i
                  ></a>
                  <a
                    class="btn btn-primary"
                    [routerLink]="['/licences', licence.licenceId]"
                    ><i class="fas fa-eye"></i
                  ></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <nba-inline-item-pager
          [items]="shownLicences"
          (pageUpdated)="onPageUpdated($event)"
        ></nba-inline-item-pager>
      </div>
      <ng-template #noLicences>
        <div *ngIf="!loading" class="alert alert-info text-center">
          No Sync Errors
        </div>
      </ng-template>
    </ng-container>
    <nba-loading *ngIf="loading"></nba-loading>
  </div>
</div>
