import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'nba-optional-boolean-dropdown',
  templateUrl: './optional-boolean-dropdown.component.html',
  styleUrls: ['./optional-boolean-dropdown.component.scss'],
})
export class OptionalBooleanDropdownComponent implements OnInit {
  @Input()
  model?: boolean;

  @Input()
  title: string;

  id: string;

  @Output()
  valueChanged = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    this.id = this.makeid(12);
  }

  private makeid(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
