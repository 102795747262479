import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import NbEditor from '@nbm/ckeditor5-custom-build';

@Component({
  selector: 'nba-inline-input-edit',
  templateUrl: './inline-input-edit.component.html',
  styleUrls: ['./inline-input-edit.component.scss'],
})
export class InlineInputEditComponent implements OnInit {
  @Input()
  displayName: string;

  @Input()
  fieldValue: string;

  @Input()
  type: 'wysiwyg' | 'text-area' | 'text-box' = 'text-box';

  @Output()
  saved = new EventEmitter();

  @Output()
  editEvent = new EventEmitter();

  editing = false;
  startingValue = '';

  public Editor = NbEditor;

  constructor() {}

  ngOnInit(): void {
    this.startingValue = this.fieldValue;
  }

  get buttonText() {
    if (this.editing) return 'Save';
    else return 'Edit';
  }

  buttonPressed() {
    if (this.editing) {
      this.saved.emit(this.fieldValue);
      this.startingValue = this.fieldValue;
      this.editing = false;
    } else {
      this.editEvent.emit(true);
      this.editing = true;
    }
  }

  cancel() {
    this.fieldValue = this.startingValue;
    this.editEvent.emit(false);
    this.editing = false;
  }
}
