import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  NgbDateParserFormatter,
  NgbModalConfig,
  NgbModalModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoadingModule } from '../loading/loading.module';
import { AdminModalComponent } from './admin-modal/admin-modal.component';
import { AdminButtonDirective } from './admin-page/admin-button.directive';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { InlineInputEditComponent } from './inline-input-edit/inline-input-edit.component';
import { DataInterceptor } from './interceptors/data-interceptor';
import { AngularDateHttpInterceptor } from './interceptors/date-interceptor';
import { NavItemDividerComponent } from './nav-item-divider/nav-item-divider.component';
import { NavItemLinkComponent } from './nav-item-link/nav-item-link.component';
import { NavItemRoutedComponent } from './nav-item-routed/nav-item-routed.component';
import { NavItemsComponent } from './nav-items/nav-items.component';
import { OptionalBooleanDropdownComponent } from './optional-boolean-dropdown/optional-boolean-dropdown.component';
import { BreakUpStringPipe } from './pipes/break-up-string.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { UkDateParserFormatter } from './uk-date-parser-formatter';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({ declarations: [
        BreadcrumbComponent,
        AdminPageComponent,
        AdminButtonDirective,
        BreakUpStringPipe,
        AdminModalComponent,
        SearchFilterComponent,
        InlineInputEditComponent,
        OptionalBooleanDropdownComponent,
        NavItemsComponent,
        ExportButtonComponent,
        NavItemRoutedComponent,
        NavItemLinkComponent,
        NavItemDividerComponent,
        ShortenPipe,
        ConfirmModalComponent,
    ],
    exports: [
        BreadcrumbComponent,
        ReactiveFormsModule,
        NgbModalModule,
        AdminPageComponent,
        AdminModalComponent,
        InlineInputEditComponent,
        SearchFilterComponent,
        AdminButtonDirective,
        BreakUpStringPipe,
        CKEditorModule,
        OptionalBooleanDropdownComponent,
        ExportButtonComponent,
        NgSelectModule,
        ShortenPipe,
    ], imports: [CommonModule,
        RouterModule,
        ReactiveFormsModule,
        NgbModalModule,
        NgbToastModule,
        CKEditorModule,
        FormsModule,
        LoadingModule,
        NgSelectModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DataInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AngularDateHttpInterceptor,
            multi: true,
        },
        {
            provide: NgbModalConfig,
            useValue: { backdrop: 'static' } as NgbModalConfig,
        },
        { provide: NgbDateParserFormatter, useClass: UkDateParserFormatter },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule {}
