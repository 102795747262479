<li
  class="d-flex align-self-center justify-content-start flex-fill border-bottom"
  [ngClass]="{ 'd-lg-none': link.mobileOnly }"
>
  <a
    *ngIf="link.onClick"
    class="d-flex align-self-center justify-content-center flex-fill p-3 cursor-pointer"
    (click)="link.onClick(); clicked.next({})"
  >
    {{ link.name }}
  </a>
</li>
