import { LicenceAssignmentInfo } from './licence-assignment-info';
import { LicenceStatus } from './licence-status';
import { LicenceSummaryData } from './licence-summary-data';

export class LicenceSummaryItem {
  constructor(summary: LicenceSummaryData) {
    this.organisationId = summary.organisationId;
    this.organisationName = summary.organisationName;
    this.licenceId = summary.licenceId;
    this.createdOn = summary.createdOn;
    this.licenceNumber = summary.licenceNumber;
    this.productName = summary.productName;
    this.syncStatus = summary.syncStatus;
    this.licenceStatus = summary.licenceStatus;
    this.licenceStatusValue = summary.licenceStatusValue;
    this.autoRenew = summary.autoRenew;
    this.cancelled = summary.cancelled;
    this.licenceCanBeReassigned = summary.licenceCanBeReassigned;
    this.validFrom = summary.validFrom;
    this.validTo = summary.validTo;
    this.currentAssignment = summary.currentAssignment;
    this.previousAssignment = summary.previousAssignment;
    this.isActive = summary.isActive;
    this.cutoffDate = summary.cutoffDate;
    this.licenceEndDate = summary.licenceEndDate;
    this.licenceLengthMonths = summary.licenceLengthMonths;
    this.renewalDate = summary.renewalDate;
    this.orderId = summary.orderId;
    this.termStartDate = summary.termStartDate;
    this.termEndDate = summary.termEndDate;
  }

  readonly organisationId: string;
  readonly organisationName: string;
  readonly licenceId: string;
  readonly licenceNumber?: string;
  readonly productName: string;
  readonly licenceStatus: string;
  readonly syncStatus: string;
  readonly licenceStatusValue: number;
  readonly autoRenew: boolean;
  readonly cancelled: boolean;
  readonly licenceCanBeReassigned: boolean;
  readonly createdOn: Date;
  readonly validFrom: Date;
  readonly validTo: Date;

  readonly currentAssignment?: LicenceAssignmentInfo;
  readonly previousAssignment?: LicenceAssignmentInfo;
  readonly isActive: boolean;
  readonly cutoffDate: Date;

  readonly licenceEndDate: Date;
  readonly licenceLengthMonths: number;
  readonly renewalDate: Date;
  readonly orderId: string;
  readonly termStartDate: Date;
  readonly termEndDate: Date;

  get canAssign(): boolean {
    const today = new Date();
    return (
      !this.currentAssignment &&
      this.licenceStatus === LicenceStatus.Unallocated &&
      this.validFrom <= today &&
      this.validTo >= today
    );
  }

  get isValid(): boolean {
    const now = new Date();
    const validFrom = new Date(this.validFrom);
    const validTo = new Date(this.validTo);
    const isValid = validFrom <= now && validTo >= now;
    return isValid;
  }
}
