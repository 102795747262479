<div class="mt-2 w-100 p-2">
  <h2>Reports</h2>
  <div class="row justify-content-center">
    <nba-orders-not-synced-report
      class="col-6 mb-2"
    ></nba-orders-not-synced-report>
    <nba-licences-not-synced-report
      class="col-6 mb-2"
    ></nba-licences-not-synced-report>
    <nba-products-no-price-report
      class="col-6 mb-2"
    ></nba-products-no-price-report>
    <nba-failed-emails-report class="col-6 mb-2"></nba-failed-emails-report>
    <nba-job-listings-to-approve-report
      class="col-6 mb-2"
    ></nba-job-listings-to-approve-report>
    <nba-course-notifications-count-report
      class="col-6 mb-2"
    ></nba-course-notifications-count-report>
    <nba-discount-usage-report class="col-12 mb-2"></nba-discount-usage-report>
    <nba-user-outstanding-credit-report class="col-6 mb-2">
    </nba-user-outstanding-credit-report>
  </div>
</div>
