import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { PagedCollection } from '../item-pager/paged-collection';
import { AdminOperationResult } from '../shared/dtos/admin-operation';
import { RequestService } from '../shared/request.service';
import { EmailMessageDetail } from './dtos/email-message-detail';
import { EmailMessageInfo } from './dtos/email-message-info';
import { EmailMessageStatus } from './dtos/email-message-status';
import { SearchEmailMessagesQuery } from './dtos/search-email-messages';

@Injectable({
  providedIn: 'root',
})
export class EmailMessagesService {
  constructor(private requestService: RequestService) {}

  getEmails(request: SearchEmailMessagesQuery) {
    return this.requestService
      .adminQuery<PagedCollection<EmailMessageInfo>>(
        'SearchEmailMessages',
        request
      )
      .pipe(map((data) => PagedCollection.parseData(data)));
  }

  getAttachmentById(id: string) {
    return this.requestService.adminDownload<Blob>('GetAttachmentById', {
      id,
    });
  }

  getFailedMessages(page: number) {
    var query: SearchEmailMessagesQuery = {
      status: EmailMessageStatus.Failed,
      page,
    };
    return this.requestService
      .adminQuery<PagedCollection<EmailMessageInfo>>(
        'SearchEmailMessages',
        query
      )
      .pipe(map((data) => PagedCollection.parseData(data)));
  }

  getEmailById(id: string) {
    return this.requestService.adminQuery<EmailMessageDetail>(
      'GetEmailMessageById',
      { id }
    );
  }
  addEmail(data: any) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddEmailMessage',
      data
    );
  }

  getFilterObject(query: SearchEmailMessagesQuery) {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }

  private getFilterData(query: SearchEmailMessagesQuery) {
    const queryData: any = { ...query };
    const activeKeys = Object.keys(queryData).filter(
      (key) => queryData[key] !== undefined && queryData[key] !== null
    );

    const data: { [key: string]: any } = {};
    activeKeys.forEach((key) => (data[key] = queryData[key]));

    return { activeKeys, data };
  }
}
