import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FullScreenLoadingComponent } from './full-screen-loading/full-screen-loading.component';
import { LoadingComponent } from './loading/loading.component';
import { SmallLoadingComponent } from './small-loading/small-loading.component';
import { WithLoadingPipe } from './with-loading.pipe';

@NgModule({
  declarations: [
    LoadingComponent,
    WithLoadingPipe,
    SmallLoadingComponent,
    FullScreenLoadingComponent,
  ],
  imports: [CommonModule],
  exports: [
    LoadingComponent,
    WithLoadingPipe,
    SmallLoadingComponent,
    FullScreenLoadingComponent,
  ],
})
export class LoadingModule {}
