<div class="mb-2">
  <div class="row">
    <div class="col">
      <h2>{{ displayName }}</h2>
    </div>
    <div class="col text-end">
      <div [ngClass]="{ 'btn-group': editing }">
        <button class="btn btn-secondary" *ngIf="editing" (click)="cancel()">
          Cancel
        </button>
        <button
          class="btn float-end"
          [ngClass]="{ 'btn-secondary': !editing, 'btn-success': editing }"
          (click)="buttonPressed()"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
  <ng-container [ngSwitch]="type">
    <ckeditor
      *ngSwitchCase="'wysiwyg'"
      [editor]="Editor"
      [disabled]="!editing"
      [(ngModel)]="fieldValue"
    ></ckeditor>
    <textarea
      *ngSwitchCase="'text-area'"
      class="form-control"
      [(ngModel)]="fieldValue"
      [readonly]="!editing"
    ></textarea>
    <input
      *ngSwitchDefault
      class="form-control"
      [(ngModel)]="fieldValue"
      [readonly]="!editing"
    />
  </ng-container>
</div>
