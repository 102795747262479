import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { JobListingsService } from 'src/app/job-listings/job-listings.service';

@Component({
  selector: 'nba-job-listings-to-approve-report',
  templateUrl: './job-listings-to-approve-report.component.html',
  styleUrls: ['./job-listings-to-approve-report.component.scss'],
})
export class JobListingsToApproveReportComponent implements OnInit {
  loading = false;
  jobCount$: Observable<number>;

  constructor(private jobListings: JobListingsService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.jobCount$ = this.jobListings.getTotalToApprove();
  }
}
