<li
  class="d-flex align-self-center justify-content-start flex-fill border-bottom"
  [ngClass]="{ 'd-lg-none': link.mobileOnly }"
>
  <a
    class="align-items-baseline align-self-center d-flex flex-fill justify-content-start p-3"
    [routerLink]="link.link"
    routerLinkActive="active"
    (click)="clicked.next({})"
  >
    <i class="{{ link.icon }} me-2"> </i>
    <span> {{ link.name }}</span>
  </a>
</li>
