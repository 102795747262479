import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NgbDateToStringPipe } from '../date-format/ngb-date-to-string.pipe';
import { PagedCollection } from '../item-pager/paged-collection';
import { AdminOperationResult } from '../shared/dtos/admin-operation';
import { RequestService } from '../shared/request.service';
import { DiscountCampaignSummary } from './dtos/discount-campaign-summary';
import { DiscountSearchQuery } from './dtos/discount-search-query';
import { DiscountSummary } from './dtos/discount-summary';
import { DiscountUsageResult } from './dtos/discount-usage-result';
import { DiscountUsageSearchQuery } from './dtos/discount-usage-search-query';
import { EditDiscountModel } from './dtos/edit-discount-model';

@Injectable({
  providedIn: 'root',
})
export class DiscountsService {
  constructor(private requestService: RequestService) {}

  search(request: DiscountSearchQuery) {
    return this.requestService
      .adminQuery<PagedCollection<DiscountSummary>>('SearchDiscounts', request)
      .pipe(
        map(
          (data) =>
            new PagedCollection<DiscountSummary>(
              data.data,
              data.pageNumber,
              data.pageSize,
              data.count
            )
        )
      );
  }
  getCampaigns(): Observable<DiscountCampaignSummary[]> {
    return this.requestService.adminQuery<DiscountCampaignSummary[]>(
      'GetDiscountCampaigns',
      {}
    );
  }

  getTemplateCandidates(): Observable<DiscountSummary[]> {
    return this.requestService.adminQuery<DiscountSummary[]>(
      'GetDiscountTemplateCandidates',
      {}
    );
  }

  addDiscount(payload: any): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddDiscount',
      payload
    );
  }

  addLicenceDiscount(payload: any): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddLicenceDiscount',
      payload
    );
  }
  addNbPlusDiscount(payload: any): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddNBPlusDiscount',
      payload
    );
  }

  updateDiscount(id: string, payload: any): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdateDiscount',
      { id, ...payload }
    );
  }
  getEditDiscountModel(id: string) {
    return this.requestService.adminQuery<EditDiscountModel>(
      'GetEditDiscountModel',
      { id }
    );
  }

  deleteDiscount(id: string): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'DeleteDiscount',
      { id }
    );
  }
  addCampaign(payload: any): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddDiscountCampaign',
      payload
    );
  }
  deleteCampaign(id: string) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'DeleteDiscountCampaign',
      { id }
    );
  }
  extendCampaign(payload: any): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'ExtendDiscountCampaign',
      payload
    );
  }

  getDiscountUsageReport(query?: DiscountUsageSearchQuery) {
    return this.requestService.adminQuery<DiscountUsageResult[]>(
      'GetDiscountUsageReport',
      { query }
    );
  }

  getFilterObject(query: DiscountSearchQuery | DiscountUsageSearchQuery) {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }

  private getFilterData(query: DiscountSearchQuery | DiscountUsageSearchQuery) {
    const queryData: any = { ...query };
    if (queryData.orderDateFrom) {
      queryData.orderDateFrom = this.getParamDate(queryData.orderDateFrom);
    }
    if (queryData.orderDateTo) {
      queryData.orderDateTo = this.getParamDate(queryData.orderDateTo);
    }
    if (queryData.discountDateFrom) {
      queryData.discountDateFrom = this.getParamDate(
        queryData.discountDateFrom
      );
    }
    if (queryData.discountDateTo) {
      queryData.discountDateTo = this.getParamDate(queryData.discountDateTo);
    }

    const activeKeys = Object.keys(queryData).filter(
      (key) => queryData[key] !== undefined && queryData[key] !== null
    );

    const data = {};
    activeKeys.forEach((key) => (data[key] = queryData[key]));

    return { activeKeys, data };
  }

  private getParamDate(date: NgbDateStruct): string {
    if (!date) {
      return undefined;
    }
    return new NgbDateToStringPipe().transform(date);
  }
}
