import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Address } from '../address/dtos/address';
import { NgbDateToStringPipe } from '../date-format/ngb-date-to-string.pipe';
import { PagedCollection } from '../item-pager/paged-collection';
import { AdminOperationResult } from '../shared/dtos/admin-operation';
import { RequestService } from '../shared/request.service';
import { UpdateOrderShippingAddress } from './commands/update-order-shipping-address';
import { LicenceOrderInfo } from './dtos/licence-order-info';
import { MaxRefundAmount } from './dtos/max-refund-amount';
import { OfflinePaymentType } from './dtos/offline-payment-type';
import { OrderCancelRequest } from './dtos/order-cancel-request';
import { OrderDetailData } from './dtos/order-detail-data';
import { OrderDetails } from './dtos/order-details';
import { OrderSearchQuery } from './dtos/order-search-query';
import { OrderSearchResult } from './dtos/order-search-result';
import { RefundRequest } from './dtos/refund-request';
import {
  RevertInternalHoldingCreditRequest as RevertInternalHoldingCreditRequest,
} from './dtos/revert-internal-holding-credit-request';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private requestService: RequestService) {}

  search(request: OrderSearchQuery) {
    return this.requestService
      .adminQuery<PagedCollection<OrderSearchResult>>('SearchOrders', request)
      .pipe(map((data) => PagedCollection.parseData(data)));
  }

  getById(id: string): Observable<OrderDetails> {
    return this.requestService
      .adminQuery<OrderDetailData>('GetOrderById', { id })
      .pipe(map((data) => new OrderDetails(data)));
  }

  getLicenceOrdersByIds(ids: string[]): Observable<LicenceOrderInfo[]> {
    return this.requestService.adminQuery<LicenceOrderInfo[]>(
      'GetLicenceOrdersByIds',
      { ids }
    );
  }

  getMaxRefundAmount(id: string): Observable<MaxRefundAmount> {
    return this.requestService.adminQuery<MaxRefundAmount>(
      'GetMaxRefundAmount',
      { id }
    );
  }
  getMaxOrderCreditAmount(id: string) {
    return this.requestService.adminQuery<MaxRefundAmount>(
      'GetMaxOrderCreditAmount',
      { id }
    );
  }

  issueRefund(refundRequest: RefundRequest): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'IssueRefund',
      refundRequest
    );
  }

  revertInternalHoldingCredit(request: RevertInternalHoldingCreditRequest) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AdminRevertInternalHoldingCredit',
      request
    );
  }

  transferOrder(
    orderId: string,
    userId: string
  ): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'TransferOrderToAnotherUser',
      { orderId, userId }
    );
  }

  transferBilling(
    orderId: string,
    userId: any,
    address: Address
  ): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'TransferBillingToAnotherUser',
      { orderId, userId, address }
    );
  }

  cancelOrder(
    orderId: string,
    cancelRequest: OrderCancelRequest
  ): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'CancelOrder',
      {
        orderId,
        cancelSalesforceSubscriptions:
          cancelRequest.cancelSalesforceSubscriptions,
        cancelSubscriptions: cancelRequest.cancelSubscriptions,
        cancelBookings: cancelRequest.cancelBookings,
        deleteLicences: cancelRequest.deleteLicences,
        issueRefund: cancelRequest.issueRefund,
      }
    );
  }

  creditOrder(id: string, amount: number): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'CreditOrder',
      { id, amount }
    );
  }

  addNote(id: string, note: string): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddOrderNote',
      { id, note }
    );
  }

  completeOfflineOrder(
    orderId: string,
    paymentType: OfflinePaymentType,
    reference: string
  ): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'CompleteOfflinePayment',
      { orderId, paymentType, reference }
    );
  }

  getFilterObject(query: OrderSearchQuery) {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }

  setNotSynced(orderId: string): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'SetOrderAsNotSynced',
      { orderId }
    );
  }

  setInvoiceNotSynced(orderId: string): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'SetInvoiceAsNotSynced',
      { orderId }
    );
  }

  getOrdersNotSynced() {
    return this.requestService.adminQuery<[]>('GetOrdersNotSynced', {});
  }

  getInvoicesNotSynced() {
    return this.requestService.adminQuery<OrderSearchResult[]>(
      'GetInvoicesNotSynced',
      {}
    );
  }
  updateOrderShippingAddress(
    request: UpdateOrderShippingAddress
  ): Observable<boolean> {
    return this.requestService.adminCommand<boolean>(
      'UpdateOrderShippingAddress',
      request
    );
  }

  reverseInvoicePayment(orderId: string): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'ReverseInvoiceOrderPayment',
      { orderId }
    );
  }

  private getFilterData(query: OrderSearchQuery) {
    const queryData: any = { ...query };
    if (queryData.dateFrom) {
      queryData.dateFrom = this.getParamDate(queryData.dateFrom);
    }
    if (queryData.dateTo) {
      queryData.dateTo = this.getParamDate(queryData.dateTo);
    }
    const activeKeys = Object.keys(queryData).filter(
      (key) => queryData[key] !== undefined && queryData[key] !== null
    );

    const data = {};
    activeKeys.forEach((key) => (data[key] = queryData[key]));

    return { activeKeys, data };
  }

  private getParamDate(date: NgbDateStruct): string {
    if (!date) {
      return undefined;
    }
    return new NgbDateToStringPipe().transform(date);
  }
}
