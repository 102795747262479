import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NgbDateToStringPipe } from '../date-format/ngb-date-to-string.pipe';
import { PagedCollection } from '../item-pager/paged-collection';
import { AdminOperationResult } from '../shared/dtos/admin-operation';
import { RequestService } from '../shared/request.service';
import { ContactSalesforceUrl } from './dtos/contact-salesforce-url';
import { CustomerReportSearchQuery } from './dtos/customer-report-search-query';
import { DashboardUserInfo } from './dtos/dashboard-user-info';
import { DashboardUserRole } from './dtos/dashboard-user-role';
import { UpdateDashboardUserRolesRequest } from './dtos/update-dashboard-user-roles-request';
import { UserDetails } from './dtos/user-details';
import { UserDetailsData } from './dtos/user-details-data';
import { UserSearchQuery } from './dtos/user-search-query';
import { UserSearchResult } from './dtos/user-search-result';
import { UserStoreBalance } from './dtos/user-store-balance';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private requestService: RequestService) {}

  search(request: UserSearchQuery) {
    return this.requestService
      .adminQuery<PagedCollection<UserSearchResult>>('SearchUsers', request)
      .pipe(map((data) => PagedCollection.parseData(data)));
  }
  quickSearch(term: string) {
    return this.requestService.adminQuery<UserSearchResult[]>(
      'QuickSearchUsers',
      { term }
    );
  }
  getById(id: string) {
    return this.requestService
      .adminQuery<UserDetailsData>('GetUserById', { id })
      .pipe(map((data) => new UserDetails(data)));
  }

  getFilterObject(query: UserSearchQuery): Params {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }
  addStoreCredit(id: string, amount: number) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddStoreCredit',
      { id, amount }
    );
  }

  getSalesforceContactUrl(id: string) {
    return this.requestService.adminQuery<ContactSalesforceUrl>(
      'GetContactSalesforceUrl',
      {
        id,
      }
    );
  }

  mergeAccounts(fromId: string, toId: any) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'MergeUserWebsiteData',
      { from: fromId, to: toId }
    );
  }

  updateEmail(userId: string, newEmail: string) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdateUserEmail',
      { id: userId, newEmail }
    );
  }

  updateSystemNotificationOptOut(userId: string, optOut: boolean) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdateSystemNotificationOptOut',
      { id: userId, optOut }
    );
  }

  updateUserRoles(request: UpdateDashboardUserRolesRequest) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdateDashboardUserRoles',
      request
    );
  }

  updatePassword(userId: string, newPassword: string, confirmation: string) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdateUserPassword',
      { id: userId, password: newPassword, confirmation }
    );
  }

  getDashboardUserInfo(userId: string) {
    return this.requestService.adminQuery<DashboardUserInfo>(
      'GetDashboardUserInfo',
      { userId }
    );
  }

  getDashboardUserRoles() {
    return this.requestService.adminQuery<DashboardUserRole[]>(
      'GetDashboardUserRoles',
      {}
    );
  }

  addNote(id: string, note: string): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddUserNote',
      { id, note }
    );
  }

  private getFilterData(query: UserSearchQuery) {
    const queryData: any = { ...query };
    const activeKeys = Object.keys(queryData).filter(
      (key) => queryData[key] !== undefined && queryData[key] !== null
    );

    const data = {};
    activeKeys.forEach((key) => (data[key] = queryData[key]));

    return { activeKeys, data };
  }

  getCustomerReportTotal(
    request: CustomerReportSearchQuery
  ): Observable<number> {
    return this.requestService.adminQuery<number>(
      'GetCustomerReportData',
      request
    );
  }

  getFilterObjectForCustomerReport(query: CustomerReportSearchQuery) {
    const val = this.getFilterDataForCustomerReport(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }

  getCustomersWithActiveCredit() {
    return this.requestService.adminQuery<UserStoreBalance[]>(
      'GetCustomersWithActiveCredit',
      {}
    );
  }

  private getFilterDataForCustomerReport(query: CustomerReportSearchQuery) {
    const queryData: any = { ...query };
    if (queryData.dateFrom) {
      queryData.dateFrom = this.getParamDate(queryData.dateFrom);
    }
    if (queryData.dateTo) {
      queryData.dateTo = this.getParamDate(queryData.dateTo);
    }
    const activeKeys = Object.keys(queryData).filter(
      (key) => queryData[key] !== undefined && queryData[key] !== null
    );

    const data = {};
    activeKeys.forEach((key) => (data[key] = queryData[key]));

    return { activeKeys, data };
  }

  private getParamDate(date: NgbDateStruct): string {
    if (!date) {
      return undefined;
    }
    return new NgbDateToStringPipe().transform(date);
  }
}
