import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DiscountUsageSearchQuery } from 'src/app/discounts/dtos/discount-usage-search-query';

@Component({
  selector: 'nba-discount-usage-report-search-form',
  templateUrl: './discount-usage-report-search-form.component.html',
  styleUrls: ['./discount-usage-report-search-form.component.scss'],
})
export class DiscountUsageReportSearchFormComponent implements OnInit {
  @Input()
  query: DiscountUsageSearchQuery;

  @Output()
  queryUpdated = new EventEmitter<Partial<DiscountUsageSearchQuery>>();

  typeOptions: string[] = ['Fourteen Fish', 'Retention', 'Valentines'];

  private nameChanged: Subject<string> = new Subject<string>();
  private emailChanged: Subject<string> = new Subject<string>();
  private codeChanged: Subject<string> = new Subject<string>();

  ngOnInit(): void {
    this.nameChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((name) => {
        this.emit({ name });
      });
    this.emailChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((email) => {
        this.emit({ email });
      });
    this.codeChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((code) => {
        this.emit({ code });
      });
  }

  setName(name: string) {
    this.nameChanged.next(name);
  }

  setEmail(email: string) {
    this.emailChanged.next(email);
  }

  setCode(code: string) {
    this.codeChanged.next(code);
  }

  setType(type: string) {
    this.emit({ type });
  }

  setRedeemed(redeemed: boolean) {
    this.emit({ redeemed });
  }

  setOrderDateFrom(orderDateFrom?: NgbDateStruct) {
    this.emit({ orderDateFrom });
  }
  resetOrderDateFrom() {
    this.emit({ orderDateFrom: null });
  }

  setOrderDateTo(orderDateTo?: NgbDateStruct) {
    this.emit({ orderDateTo });
  }
  resetOrderDateTo() {
    this.emit({ orderDateTo: null });
  }

  setDiscountDateFrom(discountDateFrom?: NgbDateStruct) {
    this.emit({ discountDateFrom });
  }
  resetDiscountDateFrom() {
    this.emit({ discountDateFrom: null });
  }

  setDiscountDateTo(discountDateTo?: NgbDateStruct) {
    this.emit({ discountDateTo });
  }
  resetDiscountDateTo() {
    this.emit({ discountDateTo: null });
  }

  private emit(updated: Partial<DiscountUsageSearchQuery>) {
    this.queryUpdated.emit(updated);
  }
}
