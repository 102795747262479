import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakUpString',
})
export class BreakUpStringPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/([a-z](?=[A-Z]))/g, '$1 ').trim();
  }
}
