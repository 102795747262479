import { Component, OnInit } from '@angular/core';
import { PagedCollection } from 'src/app/item-pager/paged-collection';
import { ProductSearchResult } from 'src/app/products/dtos/product-search-result';
import { ProductsService } from 'src/app/products/products.service';

@Component({
  selector: 'nba-products-no-price-report',
  templateUrl: './products-no-price-report.component.html',
  styleUrls: ['./products-no-price-report.component.scss'],
})
export class ProductsNoPriceReportComponent implements OnInit {
  products: ProductSearchResult[];
  shownProducts: PagedCollection<ProductSearchResult>;
  loading = false;

  pageSize = 5;

  page: number = 1;

  constructor(private productsService: ProductsService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.productsService.getNoPriceProducts().subscribe((products) => {
      this.loading = false;
      this.products = products;
      this.shownProducts = PagedCollection.getFromArray(
        products,
        1,
        this.pageSize
      );
    });
  }

  onPageUpdated(page: number) {
    this.shownProducts = PagedCollection.getFromArray(
      this.products,
      page,
      this.pageSize
    );
  }
}
