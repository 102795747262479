<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <ul class="pagination">
      <nba-item-pager-item
        [pageNumber]="undefined"
        [show]="true"
        [disabled]="items.isFirstPage"
        text="First"
      >
      </nba-item-pager-item>
      <nba-item-pager-item
        [pageNumber]="getRouterPageNumber(items.pageNumber - 100)"
        [show]="items.pageCount > 100"
        [disabled]="items.pageNumber - 100 < 1"
        text="&laquo;"
      >
      </nba-item-pager-item>
      <nba-item-pager-item
        [pageNumber]="getRouterPageNumber(items.pageNumber - 10)"
        [show]="items.pageCount > 10"
        [disabled]="items.pageNumber - 10 < 1"
        text="&lsaquo;"
      >
      </nba-item-pager-item>
      <nba-item-pager-item
        [pageNumber]="getRouterPageNumber(items.previousPageNumber)"
        [show]="true"
        [disabled]="!items.hasPreviousPage"
        text="Prev"
      >
      </nba-item-pager-item>
      <nba-item-pager-item
        [pageNumber]="getRouterPageNumber(items.nextPageNumber)"
        [show]="true"
        [disabled]="!items.hasNextPage"
        text="Next"
      >
      </nba-item-pager-item>
      <nba-item-pager-item
        [pageNumber]="getRouterPageNumber(items.pageNumber + 10)"
        [show]="items.pageCount > 10"
        [disabled]="items.pageCount < items.pageNumber + 10"
        text="&rsaquo;"
      >
      </nba-item-pager-item>
      <nba-item-pager-item
        [pageNumber]="getRouterPageNumber(items.pageNumber + 100)"
        [show]="items.pageCount > 100"
        [disabled]="items.pageCount < items.pageNumber + 100"
        text="&raquo;"
      >
      </nba-item-pager-item>
      <nba-item-pager-item
        [pageNumber]="getRouterPageNumber(items.pageCount)"
        [show]="true"
        [disabled]="items.isLastPage"
        text="Last"
      >
      </nba-item-pager-item>
    </ul>
  </div>
  <div class="col text-center">
    <p>
      Showing {{ items.firstItemOnPage }} - {{ items.lastItemOnPage }} of
      {{ items.count }}
    </p>
  </div>
</div>
