import { Component, OnInit } from '@angular/core';
import { PagedCollection } from 'src/app/item-pager/paged-collection';
import { OrderSearchResult } from 'src/app/orders/dtos/order-search-result';
import { OrdersService } from 'src/app/orders/orders.service';
import { ToastService } from 'src/app/toasts/toast.service';

@Component({
  selector: 'nba-orders-not-synced-report',
  templateUrl: './orders-not-synced-report.component.html',
  styleUrls: ['./orders-not-synced-report.component.scss'],
})
export class OrdersNotSyncedReportComponent implements OnInit {
  orders: OrderSearchResult[];
  shownOrders: PagedCollection<OrderSearchResult>;
  loading = false;

  pageSize = 5;

  page: number = 1;

  constructor(
    private ordersService: OrdersService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.loading = true;
    this.ordersService.getOrdersNotSynced().subscribe((orders) => {
      this.loading = false;
      this.orders = orders;
      this.shownOrders = PagedCollection.getFromArray(orders, 1, this.pageSize);
    });
  }

  setNotSynced(orderId: string) {
    this.ordersService.setNotSynced(orderId).subscribe((result) => {
      this.toastService.showSuccess(`Order ${orderId} marked as "Not Synced"`);
      this.getData();
    });
  }

  onPageUpdated(page: number) {
    this.shownOrders = PagedCollection.getFromArray(
      this.orders,
      page,
      this.pageSize
    );
  }
}
