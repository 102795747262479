import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminRoute, AppRoutes, Divider } from 'src/app/app-routes';
import { UserService } from 'src/app/auth/user.service';

import { NavItem } from '../nav-item';

@Component({
  selector: 'nba-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.scss'],
})
export class NavItemsComponent implements OnInit {
  @Output()
  linkClicked = new EventEmitter<any>();

  links$: Observable<(NavItem | Divider)[]>;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.links$ = this.userService.currentUser$.pipe(
      map((user) => {
        return AppRoutes.filter(
          (x) => (x as Divider).dividerName || (x as AdminRoute).showInNav
        )
          .filter(
            (x) =>
              (x as Divider).dividerName ||
              UserService.canAccess(
                user,
                (x as AdminRoute).data?.additionalRoles ?? []
              )
          )
          .map((x) =>
            (x as Divider).dividerName
              ? (x as Divider)
              : ({
                  name: (x as AdminRoute).displayName,
                  link: `/${(x as AdminRoute).path}`,
                  icon: (x as AdminRoute).icon,
                } as NavItem)
          );
      })
    );
  }

  clicked() {
    this.linkClicked.next({});
  }

  isAuthenticated() {
    return this.userService.currentUser$.pipe(map(UserService.isAdmin));
  }
}
