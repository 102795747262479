<nba-admin-modal [acting]="acting" [heading]="data.heading" [loading]="loading">
  <div modal-body>
    <ng-container *ngIf="data.messageHtml; else plainMessage">
      <div [innerHTML]="data.messageHtml"></div>
    </ng-container>
    <ng-template #plainMessage>
      <p>
        {{ data.messageText }}
      </p>
    </ng-template>
  </div>
  <button modal-button class="btn btn-primary" (click)="confirm()">
    {{ data.confirmText }}
  </button>
  <button modal-button class="btn btn-secondary" (click)="reject()">
    {{ data.rejectText }}
  </button>
</nba-admin-modal>
