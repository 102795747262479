import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
export abstract class AdminModalTemplate<TInput, TOutput> {
  @Input()
  data: TInput;

  @Output()
  performAction: EventEmitter<TOutput> = new EventEmitter<TOutput>();

  loading: boolean;
  acting: boolean;
}
