<div class="card">
  <div class="card-body">
    <span class="h5 card-title">No Price Products</span>
    <button class="btn btn-info float-end mb-2" (click)="getData()">
      Refresh
    </button>
    <ng-container *ngIf="!loading">
      <table
        class="table table-striped"
        *ngIf="products && shownProducts.data.length > 0; else noProducts"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of shownProducts.data">
            <td class="align-middle">
              {{ product.id }}
            </td>
            <td class="align-middle">
              {{ product.name }}
            </td>
            <td class="text-end align-middle">
              <div class="btn-group">
                <a
                  class="btn btn-primary"
                  [routerLink]="['/products', 'edit']"
                  [queryParams]="{ productId: product.id }"
                  ><i class="fas fa-eye"></i
                ></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <nba-inline-item-pager
        [items]="shownProducts"
        (pageUpdated)="onPageUpdated($event)"
      ></nba-inline-item-pager>
      <ng-template #noProducts
        ><div *ngIf="!loading" class="alert alert-info text-center mt-4 mb-2">
          No Priceless products
        </div></ng-template
      >
    </ng-container>
    <nba-loading *ngIf="loading"></nba-loading>
  </div>
</div>
