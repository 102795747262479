import { Component, OnInit } from '@angular/core';
import { PagedCollection } from 'src/app/item-pager/paged-collection';
import { LicenceSummaryItem } from 'src/app/licences/dtos/licence-summary-item';
import { LicenceService } from 'src/app/licences/licence.service';
import { ToastService } from 'src/app/toasts/toast.service';

@Component({
  selector: 'nba-licences-not-synced-report',
  templateUrl: './licences-not-synced-report.component.html',
  styleUrls: ['./licences-not-synced-report.component.scss'],
})
export class LicencesNotSyncedReportComponent implements OnInit {
  licences: LicenceSummaryItem[];
  shownLicences: PagedCollection<LicenceSummaryItem>;
  loading = false;

  pageSize = 5;

  page: number = 1;

  constructor(
    private licencesService: LicenceService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.loading = true;
    this.licencesService.getLicencesNotSynced().subscribe((licences) => {
      this.loading = false;
      this.licences = licences;
      this.shownLicences = PagedCollection.getFromArray(
        licences,
        1,
        this.pageSize
      );
    });
  }

  setNotSynced(licenceId: string) {
    this.licencesService.setNotSynced(licenceId).subscribe((result) => {
      this.toastService.showSuccess(
        `Licence ${licenceId} marked as "Not Synced"`
      );
      this.getData();
    });
  }

  onPageUpdated(page: number) {
    this.shownLicences = PagedCollection.getFromArray(
      this.licences,
      page,
      this.pageSize
    );
  }
}
