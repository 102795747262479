<div class="wrapper">
  <!-- Sidebar -->
  <nav id="sidebar" [ngClass]="{ closed: navClosed }">
    <nba-nav-items
      *ngIf="!navClosed"
      (linkClicked)="closeMenu()"
    ></nba-nav-items>
  </nav>

  <!-- Page Content -->
  <div id="content">
    <nav
      class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow d-flex justify-content-between"
    >
      <div class="navbar-brand me-0">
        <button
          class="navbar-toggler mx-2"
          type="button"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="toggleSidebar()"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <h3 class="m-0 d-inline-block text-white">NB Medical Admin</h3>
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap" *ngIf="isAuthenticated | async">
          <a class="nav-link" (click)="signOut()" href="#">Sign out</a>
        </li>
      </ul>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <main role="main" class="col px-4">
          <div class="container my-3">
            <div class="btn-group float-end">
              <ng-content select="[page-button]"></ng-content>
            </div>
            <h1>{{ title }}</h1>
            <ng-content select="[page-header]"></ng-content>
            <nba-loading *ngIf="loading"> </nba-loading>

            <div [class.d-none]="loading">
              <ng-content select="[page-data]"></ng-content>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
  <!-- Dark Overlay element -->
  <div class="overlay" [ngClass]="{ active: !navClosed }">
    <div id="dismiss" class="cursor-pointer" (click)="closeMenu()">
      <i class="fas fa-arrow-left"></i>
    </div>
  </div>
</div>
