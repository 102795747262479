import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/toasts/toast.service';

import { RequestService } from '../request.service';

@Component({
  selector: 'nba-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent {
  @Input()
  request: string;

  @Input()
  query: any;

  @Input()
  buttonText: string = 'Export as Excel';

  @Input()
  isDisabled = false;

  @Input()
  btnClass: string = 'btn-excel';

  @Input()
  filename: string;

  @Input()
  closeAllModalsOnComplete: boolean = false;

  constructor(
    private requestService: RequestService,
    private toastService: ToastService,
    private ngbModal: NgbModal
  ) {}

  export() {
    if (this.isDisabled) {
      return;
    }

    this.isDisabled = true;

    this.requestService
      .adminDownload(this.request, {
        ...this.query,
      })
      .subscribe(
        (x: Blob | MediaSource) => {
          this.isDisabled = false;

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(x);

          var link = document.createElement('a');
          link.href = data;

          if (!this.filename) {
            this.filename = 'export.xlsx';
          }
          link.download = this.filename;

          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        },
        (error) => {
          this.isDisabled = false;
          this.toastService.showError(error?.message ?? error);
        },
        () => {
          if (this.closeAllModalsOnComplete) {
            this.toastService.showSuccess('Export complete');
            this.ngbModal.dismissAll();
          }
        }
      );
  }
}
