import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NavItem } from '../nav-item';

@Component({
  selector: 'nba-nav-item-routed',
  templateUrl: './nav-item-routed.component.html',
  styleUrls: ['./nav-item-routed.component.scss'],
})
export class NavItemRoutedComponent {
  @Input()
  link: NavItem;

  @Output()
  clicked = new EventEmitter<{}>();

  constructor() {}
}
