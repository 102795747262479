import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PagedCollection } from '../item-pager/paged-collection';
import { AdminOperationResult } from '../shared/dtos/admin-operation';
import { RequestService } from '../shared/request.service';
import { AddPriceBreak } from './dtos/add-price-break';
import { DeletePriceBreak } from './dtos/delete-price-break';
import { ProductDetails } from './dtos/product-details';
import { ProductInfo } from './dtos/product-info';
import { ProductSearchQuery } from './dtos/product-search-query';
import { ProductSearchResult } from './dtos/product-search-result';
import { ProductVariantInfo } from './dtos/product-variant-info';
import { UpdatePriceBreak } from './dtos/update-price-break';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private requestService: RequestService) {}

  search(request: ProductSearchQuery) {
    return this.requestService
      .adminQuery<PagedCollection<ProductSearchResult>>(
        'SearchProducts',
        request
      )
      .pipe(map((data) => PagedCollection.parseData(data)));
  }

  getProducts(): Observable<ProductInfo[]> {
    return this.requestService.adminQuery<ProductInfo[]>('GetAllProducts', {});
  }

  getAllBooksAdmin(): Observable<ProductInfo[]> {
    return this.requestService.adminQuery<ProductInfo[]>('GetAllBooksAdmin', {});
  }

  getAllWebinarsAdmin(): Observable<ProductInfo[]> {
    return this.requestService.adminQuery<ProductInfo[]>('GetAllWebinarsAdmin', {});
  }

  getProductById(productId: string): Observable<ProductDetails> {
    return this.requestService.adminQuery<ProductDetails>('GetProductById', {
      Id: productId,
    });
  }
  getProductVariantById(id: string): Observable<ProductVariantInfo> {
    return this.requestService.adminQuery<ProductVariantInfo>(
      'GetProductVariantById',
      {
        id,
      }
    );
  }

  addProduct(data: any): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddProduct',
      data
    );
  }

  updateProduct(id: string, data: any): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdateProduct',
      { id, ...data }
    );
  }

  deleteProduct(id: string): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'DeleteProduct',
      { id }
    );
  }
  addPriceBreak(request: AddPriceBreak): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AddPriceBreak',
      request
    );
  }
  updatePriceBreak(request: UpdatePriceBreak): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdatePriceBreak',
      request
    );
  }
  deletePriceBreak(request: DeletePriceBreak): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'DeletePriceBreak',
      request
    );
  }

  getFilterObject(query: ProductSearchQuery) {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }

  getNoPriceProducts() {
    return this.requestService.adminQuery<ProductSearchResult[]>(
      'GetNoPriceProducts',
      {}
    );
  }

  private getFilterData(query: ProductSearchQuery) {
    const queryData: any = { ...query };

    const activeKeys = Object.keys(queryData).filter(
      (key) => queryData[key] !== undefined && queryData[key] !== null
    );

    const data = {};
    activeKeys.forEach((key) => {
      if (Array.isArray(queryData[key])) {
        data[key] = JSON.stringify(queryData[key]);
      } else {
        data[key] = queryData[key];
      }
    });

    return { activeKeys, data };
  }
}
