import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { DiscountsService } from 'src/app/discounts/discounts.service';
import { DiscountUsageResult } from 'src/app/discounts/dtos/discount-usage-result';
import { DiscountUsageSearchQuery } from 'src/app/discounts/dtos/discount-usage-search-query';
import { PagedCollection } from 'src/app/item-pager/paged-collection';
import { ToastService } from 'src/app/toasts/toast.service';

@Component({
  selector: 'nba-discount-usage-report',
  templateUrl: './discount-usage-report.component.html',
  styleUrls: ['./discount-usage-report.component.scss'],
})
export class DiscountUsageReportComponent implements OnInit, OnChanges {
  discounts: DiscountUsageResult[];
  routeSubscription: Subscription;
  private adapter = new NgbDateNativeAdapter();

  shownDiscounts: PagedCollection<DiscountUsageResult>;
  loading = false;

  currentParams: any;

  pageSize = 5;

  page: number = 1;

  searchQuery: DiscountUsageSearchQuery;

  constructor(
    private route: ActivatedRoute,
    private discountService: DiscountsService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onPageUpdated(this.page);
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.queryParams.subscribe((params) => {
      this.currentParams = { ...params };
      this.setSearchQuery();
      this.getData();
    });
  }

  private setSearchQuery() {
    const params = this.currentParams;
    this.searchQuery = {
      name: params.name,
      email: params.email,
      code: params.code,
      type: params.type,
      orderDateFrom: params.orderDateFrom
        ? this.adapter.fromModel(new Date(params.orderDateFrom))
        : null,
      orderDateTo: params.orderDateTo
        ? this.adapter.fromModel(new Date(params.orderDateTo))
        : null,
      discountDateFrom: params.discountDateFrom
        ? this.adapter.fromModel(new Date(params.discountDateFrom))
        : null,
      discountDateTo: params.discountDateTo
        ? this.adapter.fromModel(new Date(params.discountDateTo))
        : null,
    };
  }

  refreshData() {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.discountService.getDiscountUsageReport(this.currentParams).subscribe(
      (discounts) => {
        this.loading = false;
        this.discounts = discounts;
        this.onPageUpdated(this.page);
      },
      (error) => {
        this.loading = false;
        this.toastService.showError(error);
      }
    );
  }

  onPageUpdated(page: number) {
    this.shownDiscounts = PagedCollection.getFromArray(
      this.discounts,
      page,
      this.pageSize
    );
  }

  onQueryUpdated(data: Partial<DiscountUsageSearchQuery>) {
    const query = { ...this.searchQuery };
    Object.keys(data).forEach((key) => (query[key] = data[key]));

    const updatedKeys = Object.keys(data);

    this.updateRoute(query);
  }

  private updateRoute(query: DiscountUsageSearchQuery) {
    this.router.navigate(['/'], {
      queryParams: this.discountService.getFilterObject(query),
    });
  }

  get reportFilename() {
    var today = dayjs();
    return `Discount Usages ${today.format('DD-MM-YY')}.xlsx`;
  }
}
