<li class="page-item" [ngClass]="{ disabled: disabled }" *ngIf="show">
  <a
    class="page-link"
    [routerLink]="routeData"
    [queryParams]="queryParams"
    *ngIf="enabled"
  >
    {{ text }}
  </a>
  <a class="page-link" *ngIf="disabled">
    {{ text }}
  </a>
</li>
