import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class UkDateParserFormatter extends NgbDateParserFormatter {
  static parseDate(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: toInteger(dateParts[0]), month: null, year: null };
      } else if (
        dateParts.length === 2 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1])
      ) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: null
        };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          day: toInteger(dateParts[0]),
          month: toInteger(dateParts[1]),
          year: toInteger(dateParts[2])
        };
      }
    }
    return null;
  }
  static isValidDate(date: NgbDateStruct) {
    try {
      const d = new Date(date.year, date.month - 1, date.day);
      return d && d.getMonth() + 1 === date.month;
    } catch {
      return false;
    }
  }
  parse(value: string): NgbDateStruct {
    return UkDateParserFormatter.parseDate(value);
  }
  format(date: NgbDateStruct): string {
    return date
      ? `${isNumber(date.day) ? padNumber(date.day) : ''}/${
          isNumber(date.month) ? padNumber(date.month) : ''
        }/${date.year}`
      : '';
  }
}
function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}
function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}
function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}
