import { ImportantZoneIds } from 'src/app/content-access-display/important-zone-ids';
import { UserLicence } from 'src/app/licences/dtos/user-licence';
import { OrderDetails } from 'src/app/orders/dtos/order-details';
import { OrganisationUserSummary } from 'src/app/organisations/dtos/organisation-user-summary';
import { BaseNote } from 'src/app/shared/dtos/base-note';
import { SubscriptionDetails } from 'src/app/subscriptions/dtos/subscription-details';
import { VoucherDetails } from 'src/app/vouchers/dtos/voucher-details';

import { BookingDetails } from '../../booking-display/booking-details';
import { ContentAccessDetails } from '../../content-access-display/content-access-details';
import { UserDetailsData } from './user-details-data';
import { UserTransactionInfo } from './user-transaction-info';

export class UserDetails {
  constructor(data: UserDetailsData) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.lastModified = data.lastModified;
    this.balanceItems = data.balanceItems.map(
      (d) => new UserTransactionInfo(d)
    );
    this.orders = data.orders;
    this.subscriptions = data.subscriptions;
    this.licences = data.licences.map((d) => new UserLicence(d));

    this.bookings = data.bookings;
    this.contentAccess = data.contentAccess;
    this.vouchers = data.vouchers;
    this.organisations = data.organisations;
    this.notes = data.notes;

    this.doNotSendSystemNotifications = data.doNotSendSystemNotifications;
  }
  readonly id: string;

  readonly email: string;
  readonly name: string;

  readonly lastModified: Date;

  readonly balanceItems: UserTransactionInfo[];

  readonly orders: OrderDetails[];

  readonly subscriptions: SubscriptionDetails[];

  readonly licences: UserLicence[];

  readonly bookings: BookingDetails[];

  readonly contentAccess: ContentAccessDetails[];
  readonly vouchers: VoucherDetails[];
  readonly organisations: OrganisationUserSummary[];
  readonly notes: BaseNote[];

  readonly doNotSendSystemNotifications: boolean;
  public get hasNbPlus(): boolean {
    return this.contentAccess.some(
      (ca) => ImportantZoneIds.NB_PLUS_RANGE.includes(ca.zoneId) && ca.isActive
    );
  }
  public get currentStoreCredit(): number {
    return this.balanceItems.length
      ? this.balanceItems[this.balanceItems.length - 1].balance
      : 0;
  }

  public get activeLicence(): UserLicence {
    const today = new Date();
    return this.licences.find(
      (l) =>
        new Date(l.licenceAssignment.from) <= today &&
        new Date(l.licenceAssignment.to) >= today
    );
  }
}
