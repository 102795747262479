<div class="card">
  <div class="card-body">
    <span class="h5 card-title">Discount Usage</span>

    <button class="btn btn-info float-end mb-2" (click)="refreshData()">
      Refresh
    </button>

    <nba-discount-usage-report-search-form
      page-header
      [query]="searchQuery"
      (queryUpdated)="onQueryUpdated($event)"
    ></nba-discount-usage-report-search-form>

    <div class="row justify-content-center mb-2">
      <nba-export-button
        buttonText="Export"
        request="ExportDiscountUsageReport"
        [query]="currentParams"
        [filename]="reportFilename"
      >
      </nba-export-button>
    </div>

    <ng-container *ngIf="!loading">
      <div
        *ngIf="discounts && shownDiscounts?.data.length > 0; else noDiscounts"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Discount Emails</th>
              <th>Code</th>
              <th>Discount Created Date</th>
              <th>OrderNo.</th>
              <th>Name</th>
              <th>Order Date</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let discount of shownDiscounts.data">
              <td class="align-middle">
                {{ discount.discountEmails }}
              </td>
              <td class="align-middle">
                {{ discount.code }}
              </td>
              <td class="align-middle">
                {{ discount.discountCreatedDate | date: "dd/MM/yy" }}
              </td>
              <td class="align-middle">
                {{ discount.orderNumber }}
              </td>
              <td class="align-middle">
                {{ discount.orderName }}
              </td>
              <td class="align-middle">
                {{ discount.orderDate | date: "dd/MM/yy" }}
              </td>
              <td class="align-middle">
                {{ discount.discountType }}
              </td>
              <td>
                <a
                  *ngIf="discount.orderId"
                  class="btn btn-info"
                  [routerLink]="['/orders', discount.orderId]"
                  >View Order</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <nba-inline-item-pager
          [items]="shownDiscounts"
          (pageUpdated)="onPageUpdated($event)"
        >
        </nba-inline-item-pager>
      </div>
      <ng-template #noDiscounts>
        <div *ngIf="!loading" class="alert alert-info text-center">
          No Discounts
        </div>
      </ng-template>
    </ng-container>
    <nba-loading *ngIf="loading"></nba-loading>
  </div>
</div>
