import { UserTransactionInfoData } from './user-transaction-info-data';

export class UserTransactionInfo {
  constructor(data: UserTransactionInfoData) {
    this.id = data.id;
    this.createdOn = data.createdOn;
    this.orderId = data.orderId;
    this.amount = data.amount;
    this.currency = data.currency;
    this.type = data.type;
    this.balance = data.balance;
  }
  readonly id: string;
  readonly createdOn: Date;
  readonly orderId: string;
  readonly amount: number;
  readonly currency: string;
  readonly type: string;
  readonly balance: number;
}
