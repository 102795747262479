<div class="modal-header">
  <h4 class="modal-title">{{ heading }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <nba-loading *ngIf="loading"> </nba-loading>

  <div [class.d-none]="loading">
    <ng-content select="[modal-body]"></ng-content>
  </div>
</div>

<div class="modal-footer" *ngIf="!hideFooter">
  <div [class.d-none]="acting">
    <ng-content select="[modal-button]"></ng-content>
  </div>
  <button disabled="disabled" *ngIf="acting" class="btn btn-secondary">
    Processing...
  </button>
</div>
