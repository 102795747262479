<div class="card">
  <div class="card-body">
    <span class="h5 card-title">Users with active credit</span>
    <div class="btn-group float-end mb-2">
      <nba-export-button
        class="me-2"
        [request]="'ExportUsersWithActiveCredit'"
      ></nba-export-button>
      <button class="btn btn-info" (click)="getData()">Refresh</button>
    </div>
    <ng-container *ngIf="!loading">
      <div *ngIf="users && shownUsers.data.length > 0; else noUsers">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Most Recent Credit</th>
              <th>Balance</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of shownUsers.data">
              <td class="align-middle">
                {{ user.name }}
              </td>
              <td class="align-middle">
                {{ user.email }}
              </td>
              <td class="align-middle">
                {{ user.mostRecentCreditDate | date : "dd MMM YY HH:mm" }}
              </td>
              <td class="align-middle">
                {{ user.balance | currency : "GBP" }}
              </td>
              <td class="text-end align-middle">
                <div class="btn-group">
                  <a class="btn btn-primary" [routerLink]="['/users', user.id]"
                    ><i class="fas fa-eye"></i
                  ></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <nba-inline-item-pager
          [items]="shownUsers"
          (pageUpdated)="onPageUpdated($event)"
        ></nba-inline-item-pager>
      </div>
      <ng-template #noUsers>
        <div *ngIf="!loading" class="alert alert-info text-center">
          No users with credit
        </div>
      </ng-template>
    </ng-container>
    <nba-loading *ngIf="loading"></nba-loading>
  </div>
</div>
