export const environment = {
  production: true,
  apiUrl: 'https://nb.api.preview-site.website',
  publicUrl: 'https://admin-nb.preview-site.website',
  idealPostcodesApiKey: 'ak_hr19shxjfa0mtFMs56ZgD2xb5gqo2',
  licenceDashboardUrl: 'https://licences-nb.preview-site.website',
  clientSettings: {
    authority: 'https://nb.auth.preview-site.website',
    client_id: 'admin',
    scope: 'openid profile email admin-api',
  },
};
