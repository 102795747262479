export interface IPagedCollection {
  readonly pageCount: number;
  readonly hasPreviousPage: boolean;
  readonly previousPageNumber: number;
  readonly hasNextPage: boolean;
  readonly nextPageNumber: number;
  readonly isFirstPage: boolean;
  readonly isLastPage: boolean;
  readonly firstItemOnPage: number;
  readonly lastItemOnPage: number;
  readonly pageNumber: number;
  readonly pageSize: number;
  readonly count: number;
}
export class PagedCollection<T> implements IPagedCollection {
  static empty<T>() {
    return new PagedCollection<T>([], 1, 1, 0);
  }
  static getFromArray<T>(data: T[], pageNumber: number, pageSize: number) {
    if (isNaN(pageNumber)) pageNumber = 1;
    if (isNaN(pageSize)) pageSize = 10;
    pageNumber = Math.max(1, pageNumber);
    pageSize = Math.max(1, pageSize);

    if (!data) {
      return new PagedCollection<T>([], pageNumber, pageSize, 0);
    }

    const start = (pageNumber - 1) * pageSize;
    const end = Math.min(start + pageSize, data.length);
    const pageData = data.slice(start, end);
    return new PagedCollection<T>(pageData, pageNumber, pageSize, data.length);
  }
  public get pageCount(): number {
    return this.count > 0 ? Math.ceil(this.count / this.pageSize) : 0;
  }
  public get hasMultiplePages(): boolean {
    return this.pageCount > 1;
  }

  public get hasPreviousPage(): boolean {
    return this.pageNumber > 1;
  }

  public get previousPageNumber(): number {
    return this.pageNumber - 1;
  }

  public get hasNextPage(): boolean {
    return this.pageNumber < this.pageCount;
  }

  public get nextPageNumber(): number {
    return this.pageNumber + 1;
  }

  public get isFirstPage(): boolean {
    return this.pageNumber === 1;
  }

  public get isLastPage(): boolean {
    return this.pageNumber === this.pageCount || this.count === 0;
  }

  public get firstItemOnPage(): number {
    return (this.pageNumber - 1) * this.pageSize + 1;
  }

  public get lastItemOnPage(): number {
    const lastPossibleNumberOnPage = this.firstItemOnPage + this.pageSize - 1;
    return lastPossibleNumberOnPage > this.count
      ? this.count
      : lastPossibleNumberOnPage;
  }

  constructor(
    public data: T[],
    public pageNumber: number,
    public pageSize: number,
    public count: number
  ) {}

  public static parseData<T>(data: PagedCollection<T>): PagedCollection<T> {
    return new PagedCollection(
      data.data,
      data.pageNumber,
      data.pageSize,
      data.count
    );
  }
  public static parseAndMapData<T, TResult>(
    data: PagedCollection<T>,
    mapper: (value: T) => TResult
  ): PagedCollection<TResult> {
    return new PagedCollection(
      data.data.map(mapper),
      data.pageNumber,
      data.pageSize,
      data.count
    );
  }

  public any(): boolean {
    return this.count > 0;
  }
  public some(predicate: (item: T) => boolean): boolean {
    return this.data.some(predicate);
  }
  public every(predicate: (item: T) => boolean): boolean {
    return this.data.every(predicate);
  }
}
