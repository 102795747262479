import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nba-admin-modal',
  templateUrl: './admin-modal.component.html',
  styleUrls: ['./admin-modal.component.scss'],
})
export class AdminModalComponent {
  @Input()
  heading: string;
  @Input()
  hideFooter = false;

  @Input()
  loading = false;

  @Input()
  acting = false;

  constructor(public activeModal: NgbActiveModal) {}
}
