import { Component } from '@angular/core';
import { PagedCollection } from 'src/app/item-pager/paged-collection';
import { ToastService } from 'src/app/toasts/toast.service';
import { UserStoreBalance } from 'src/app/users/dtos/user-store-balance';
import { UsersService } from 'src/app/users/users.service';

@Component({
  selector: 'nba-user-outstanding-credit-report',
  templateUrl: './user-outstanding-credit-report.component.html',
  styleUrls: ['./user-outstanding-credit-report.component.scss'],
})
export class UserOutstandingCreditReportComponent {
  users: UserStoreBalance[];
  shownUsers: PagedCollection<UserStoreBalance>;
  loading = false;

  pageSize = 5;

  page: number = 1;

  constructor(
    private userService: UsersService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.loading = true;
    this.userService.getCustomersWithActiveCredit().subscribe((users) => {
      this.loading = false;
      this.users = users.sort((a, b) => {
        const aDate = new Date(a.mostRecentCreditDate);
        const bDate = new Date(b.mostRecentCreditDate);
        if (aDate > bDate) {
          return 1;
        } else if (aDate < bDate) {
          return -1;
        } else {
          return 0;
        }
      });

      this.shownUsers = PagedCollection.getFromArray(users, 1, this.pageSize);
    });
  }

  onPageUpdated(page: number) {
    this.shownUsers = PagedCollection.getFromArray(
      this.users,
      page,
      this.pageSize
    );
  }
}
