import { Component, Input } from '@angular/core';
import { Divider } from 'src/app/app-routes';

@Component({
  selector: 'nba-nav-item-divider',
  templateUrl: './nav-item-divider.component.html',
  styleUrls: ['./nav-item-divider.component.scss'],
})
export class NavItemDividerComponent {
  @Input()
  divider: Divider;

  constructor() {}
}
