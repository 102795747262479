import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IPagedCollection } from '../paged-collection';

@Component({
  selector: 'nba-inline-item-pager',
  templateUrl: './inline-item-pager.component.html',
  styleUrls: ['./inline-item-pager.component.scss'],
})
export class InlineItemPagerComponent {
  @Input()
  items: IPagedCollection;

  @Output()
  pageUpdated = new EventEmitter<number>();

  routeData: string[] = [];

  onPageUpdated(page: number) {
    this.pageUpdated.emit(page);
  }
}
