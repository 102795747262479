import { Component, Input } from '@angular/core';

@Component({
  selector: 'nba-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input()
  ellipsisClass = 'border-dark';

  @Input()
  textClass = '';

  @Input()
  text = '';

  @Input()
  minHeight = '200px';
}
