import { LicenceAssignmentInfo } from './licence-assignment-info';

export class UserLicence {
  constructor(summary: UserLicenceData) {
    this.organisationId = summary.organisationId;
    this.organisationName = summary.organisationName;
    this.licenceId = summary.licenceId;
    this.licenceNumber = summary.licenceNumber;
    this.productName = summary.productName;
    this.licenceStatus = summary.licenceStatus;
    this.licenceStatusValue = summary.licenceStatusValue;
    this.licenceAssignment = summary.licenceAssignment;
    this.termStartDate = summary.termStartDate;
    this.termEndDate = summary.termEndDate;
    this.orderId = summary.orderId;
  }

  readonly organisationId: string;
  readonly organisationName: string;
  readonly licenceId: string;
  readonly licenceNumber?: string;
  readonly productName: string;
  readonly licenceStatus: string;
  readonly licenceStatusValue: number;
  readonly licenceAssignment: LicenceAssignmentInfo;
  readonly termStartDate: Date;
  readonly termEndDate: Date;
  readonly orderId: string;

  // endDate is termEndDate or licenceAssignment.to, whichever is first
  get endDate(): Date {
    return this.licenceAssignment.to < this.termEndDate
      ? this.licenceAssignment.to
      : this.termEndDate;
  }

  get isActive(): boolean {
    const today = new Date();

    return (
      new Date(this.termStartDate) <= today && new Date(this.endDate) >= today
    );
  }
}

export interface UserLicenceData {
  readonly organisationId: string;
  readonly organisationName: string;
  readonly licenceId: string;
  readonly licenceNumber?: string;
  readonly productName: string;
  readonly licenceStatus: string;
  readonly licenceStatusValue: number;
  readonly licenceAssignment: LicenceAssignmentInfo;
  readonly termStartDate: Date;
  readonly termEndDate: Date;

  readonly orderId: string;
}
