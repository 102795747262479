import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseNotificationsService } from 'src/app/course-notifications/course-notifications.service';

@Component({
  selector: 'nba-course-notifications-count-report',
  templateUrl: './course-notifications-count-report.component.html',
  styleUrls: ['./course-notifications-count-report.component.scss'],
})
export class CourseNotificationsCountReportComponent implements OnInit {
  loading = false;
  notificationCount$: Observable<number>;

  constructor(private courseNotificationService: CourseNotificationsService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.notificationCount$ = this.courseNotificationService.getTotal();
  }
}
