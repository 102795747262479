import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngbDateToString',
})
export class NgbDateToStringPipe implements PipeTransform {
  transform(date: any): string {
    return date ? `${date.year}-${date.month}-${date.day}` : '';
  }
}
