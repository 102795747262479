import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/toasts/toast.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // if not posting to a full url, we'll set the domain from environment
    if (!request.url.startsWith('http')) {
      const url = environment.apiUrl;
      request = request.clone({
        url: url + request.url,
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let message = 'An error occurred';
        if (error && error.error) {
          if (error.error.reason) {
            message = error.error.reason;
          } else if (typeof error.error === 'object') {
            const parts = [];
            Object.keys(error.error).forEach((key) => {
              const value = error.error[key];
              if (Array.isArray(value)) {
                parts.push(...value);
              } else {
                parts.push(value);
              }
            });
            message = parts.join(', ');
          }
        } else if (error.status === 401) {
          message = 'You must log in to access this resource';
        } else if (error.status === 403) {
          message = 'You do not have permission to access this resource';
        }
        this.toastService.showError(message);
        console.error({ message, error });
        return throwError(error);
      })
    );
  }
}
