import { Component, OnInit } from '@angular/core';
import { EmailMessageInfo } from 'src/app/email-messages/dtos/email-message-info';
import { EmailMessagesService } from 'src/app/email-messages/email-messages.service';
import { PagedCollection } from 'src/app/item-pager/paged-collection';
import { ToastService } from 'src/app/toasts/toast.service';

@Component({
  selector: 'nba-failed-emails-report',
  templateUrl: './failed-emails-report.component.html',
  styleUrls: ['./failed-emails-report.component.scss'],
})
export class FailedEmailsReportComponent implements OnInit {
  emails: PagedCollection<EmailMessageInfo>;
  loading = false;

  pageSize = 5;

  page: number = 1;

  constructor(
    private emailsService: EmailMessagesService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.loading = true;
    this.emailsService.getFailedMessages(this.page).subscribe((emails) => {
      this.loading = false;
      this.emails = emails;
    });
  }

  onPageUpdated() {
    this.getData();
  }
}
