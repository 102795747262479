import { Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { LoginService } from '../auth/login.service';
import { UserService } from '../auth/user.service';
import { BreadcrumbItem } from '../shared/breadcrumb/breadcrumb-item';

@Component({
  selector: 'nba-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public breadcrumbs: BreadcrumbItem[] = [
    {
      name: 'Home',
      parts: ['/'],
    },
  ];

  constructor(
    private userService: UserService,
    private loginService: LoginService
  ) {}

  login() {
    this.loginService.login();
  }

  get authed$() {
    return this.userService.currentUser$.pipe(map(UserService.isAuthenticated));
  }

  get user$() {
    return this.userService.currentUser$.pipe(map((user) => user?.name));
  }
  get roles$() {
    return this.userService.currentUser$.pipe(map((user) => user?.roles || []));
  }
  // register() {
  //   const modal = this.modal.open(RegisterComponent);
  //   modal.result.then(
  //     result => (this.registrationSuccess = result),
  //     () => {}
  //   );
  // }
}
